/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;


.custom-tooltip {
    position: absolute; /* Changed from fixed to absolute */
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 12px;
    z-index: 9999;
    pointer-events: none;
    transition: opacity 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    white-space: nowrap; /* Prevents tooltip text from wrapping */
  }